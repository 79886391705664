<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay variant="white" spinner-variant="primary" blur="0" opacity=".75" rounded="sm" :show="isLoading">
          <div class="m-2">
            <b-row>
              <b-col cols="7">
                <b-form-file
                  ref="inputFileRef"
                  placeholder="Chọn file để import phiếu khảo sát/đánh giá..."
                  drop-placeholder="Drop file here..."
                  accept=".xls, .xlsx"
                  @change="importExcel"
                />
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12">
                <div class="text-right">
                  <b-button
                  v-show="showTableImport && (isSystemAdmin() || creatable)"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="info"
                    class="ml-1"
                    @click="handleImportSetOfCriteria"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="CheckSquareIcon" /> Import
                    </span>
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="ml-1"
                    @click="downloadFile"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="DownloadIcon" /> Tải file mẫu
                    </span>
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-primary"
                    class="ml-1"
                    @click="exportExcel"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="DownloadIcon" /> Xuất excel
                    </span>
                  </b-button>
                  <b-button
                    v-if="creatable"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.setOfCriteriaSaveModal
                    variant="success"
                    class="ml-1"
                    @click="onCreate"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="PlusIcon" /> Thêm mới
                    </span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row class="">
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1">
                  <span class="text-nowrap"> Hiển thị {{filter.itemsPerPage * (filter.currentPage-1) +1}}
                    đến {{(filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage)}} &nbsp; của {{ totalRows }} bản ghi
                  </span>
                </div>
                <vue-good-table
                  mode="remote"
                  class="my-table"
                  row-style-class="vgt-row"
                  style-class="vgt-table striped bordered"
                  :columns="columns"
                  :rows="setOfCriterias"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  :line-numbers="true"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'stt'">
                      {{ props.row.originalIndex + 1 }}
                    </span>
                    <span v-else-if="props.column.field === 'status'">
                      {{ getStatusName(props.row.status) }}
                    </span>
                    <span v-else-if="props.column.field === 'setOfCriteriaType'">
                      {{ getSetOfCriteriaType(props.row.setOfCriteriaTypeId) }}
                    </span>
                    <span v-else-if="props.column.field === 'setOfEvaluateLevel'">
                      {{ getSetOfEvaluateLevel(props.row.setOfEvaluateLevelId) }}
                    </span>
                    <span
                      v-else-if="props.column.field === 'action'"
                      class="thead-group-action"
                    >
                      <b-button-group>
                        <b-button
                          v-b-modal.setOfCriteriaSaveModal
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          class="btn-icon"
                          size="sm"
                          title="Chỉnh sửa"
                          @click="onEdit(props.row)"
                        >
                          <feather-icon icon="Edit2Icon" />
                        </b-button>
                        <b-dropdown
                          right
                          variant="success"
                        >
                          <b-dropdown-item
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="info"
                            class="btn-icon"
                            size="sm"
                            @click="onListCriterias(props.row)"
                          >
                            <feather-icon icon="BookOpenIcon" />
                            Danh sách câu hỏi
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="creatable"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            v-b-modal.setOfCriteriaCopyModal
                            variant="success"
                            class="btn-icon"
                            size="sm"
                            @click="onCopySetOfCriteria(props.row)"
                          >
                            <feather-icon icon="CopyIcon" />
                            Sao chép phiếu khảo sát/đánh giá
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="deletable"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="danger"
                            class="btn-icon"
                            size="sm"
                            @click="onDeleteSetOfCriteria(props.row)"
                          >
                            <feather-icon icon="TrashIcon" />
                            Xóa phiếu khảo sát/đánh giá
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-button-group>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{filter.itemsPerPage * (filter.currentPage-1) +1}}
                          đến {{(filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage)}} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
          <SetOfCriteriaSave
            ref="setOfCriteriaSaveModalRef"
            :setOfCriteria="currentMainData"
            @succeed="onSucceed"
          />
          <SetOfCriteriaCopy
            ref="setOfCriteriaCopyModalRef"
            :main-data="currentMainData"
            @succeed="onSucceed"
          />
          <b-modal
            id="modal-list-criteria"
            ref="modal-list-criteria"
            centered
            size="xl"
            :hide-footer="true"
          >
            <Criterias 
              :data-send="currentMainData"
              :criteria-types="criteriaTypes" 
              :question-difficulty-levels="questionDifficultyLevels"
            />
          </b-modal>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BButton,
  BDropdown,
  BButtonGroup,
  BDropdownItem,
  BCard,
  BCol,
  BContainer,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  VBTooltip,
  BFormGroup, BForm,
  BFormFile,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import * as XLSX from 'xlsx'
import { Workbook } from '@syncfusion/ej2-excel-export'
import SetOfCriteriaSave from '@/views/set-of-criterias/SetOfCriteriaSave.vue'
import SetOfCriteriaCopy from '@/views/set-of-criterias/SetOfCriteriaCopy.vue'
import vSelect from 'vue-select'
import { getUser } from '@/auth/utils'
import Criterias from '@/views/set-of-criterias/Criterias.vue'
import { PermissionCode, ResourceCode } from '@/const/code'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { STATUSES } from '@/const/status'

export default {
  name: 'setOfCriterias',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    Criterias,
    SetOfCriteriaCopy,
    SetOfCriteriaSave,
    BButton,
    BDropdown,
    BButtonGroup,
    BDropdownItem,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    BFormGroup,
    BForm,
    BFormFile,
    vSelect,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        organizationId: null,
        code: null,
        name: null,
        setOfCriteriaType: null,
        status: null,
        sort: null,
      },
      currentMainData: undefined,
      currentName: '',
      currentCode: '',
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 100],
      filterExport: null,
      showTableImport: false,
      tenFile: '',
      columnsExport: [
        {
          label: 'Mã loại phiếu khảo sát/đánh giá',
          field: 'setOfCriteriaType',
        },
        {
          label: 'Tên phiếu khảo sát/đánh giá',
          field: 'name',
        },
        {
          label: 'Mã phiếu khảo sát/đánh giá',
          field: 'code',
        },
        {
          label: 'Mã Thang điểm đánh giá tổng quan',
          field: 'setOfEvaluateLevel',
        },
        {
          label: 'Trạng thái',
          field: 'status',
        },
        {
          label: 'Loại câu hỏi',
          field: 'criteriaType',
        },
        {
          label: 'Tên câu hỏi',
          field: 'evaluationCriteriaName',
        },
        {
          label: 'Điểm tối đa',
          field: 'maxPoint',
        },
        {
          label: 'Số thứ tự',
          field: 'evaluationCriteriaOrderNo',
        },
        {
          label: 'Yêu cầu',
          field: 'required',
        },
        {
          label: 'Có thể chọn nhiều đáp án',
          field: 'multipleSelect',
        },
        {
          label: 'Tên tuỳ chọn',
          field: 'evaluationCriteriaOptionName',
        },
        {
          label: 'Điểm tuỳ chọn',
          field: 'point',
        },
        {
          label: 'Số thức tự tuỳ chọn',
          field: 'evaluationCriteriaOptionOrderNo',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      setOfCriterias: 'setOfCriteria/setOfCriterias',
      totalRows: 'setOfCriteria/totalRows',
      statuses: 'setOfCriteria/statuses',
      dataResourecesExport: 'setOfCriteria/dataResourecesExport',
      setOfEvaluateLevels: 'setOfCriteria/setOfEvaluateLevels',
      setOfCriteriaTypes: 'setOfCriteria/setOfCriteriaTypes',
      criteriaTypes: 'setOfCriteria/criteriaTypes',
      questionDifficultyLevels: 'setOfCriteria/questionDifficultyLevels',
    }),
    columns() {
      return [
        {
          label: `Mã phiếu khảo sát/đánh giá`,
          field: 'code',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Tìm theo mã',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: `Tên phiếu khảo sát/đánh giá`,
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Tìm theo tên',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Loại phiếu khảo sát',
          field: 'setOfCriteriaType',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: this.setOfCriteriaTypes.map(type => ({ value: type.id, text: type.name })),
            placeholder: 'Tất cả',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Thang điểm đánh giá',
          field: 'setOfEvaluateLevel',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: this.statusFilterDropdownItems,
            placeholder: 'Tất cả',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Thao tác',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ]
    },
    statusFilterDropdownItems() {
      return this.statuses.map(status => ({ value: status.value, text: status.label }))
    },
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.SET_OF_CRITERIA)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.SET_OF_CRITERIA)
    },
  },
  async created() {
    this.isLoading = true
    try {
      this.updateParams({ organizationId: this.user.orgId })
      await this.begin({ organizationId: this.user.orgId})
      await this.getSetOfCriteriasFromStore()
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  mounted() {
    const grid = document.querySelector('div.vgt-responsive')
    grid.classList.add('overlay')
    grid.classList.add('custom')
  },
  methods: {
    isSystemAdmin,
    ...mapMutations({
      questionDifficultyLevels: 'setOfCriteriaType/SET_QUESTION_DIFFICULTY_LEVELS',
      criteriaTypes: 'setOfCriteriaType/SET_CRITERIA_TYPES',
    }),
    ...mapActions({
      getSetOfCriterias: 'setOfCriteria/getSetOfCriterias',
      deleteData: 'setOfCriteria/deleteSetOfCriteria',
      begin: 'setOfCriteria/begin',
      importSetOfCriteria: 'setOfCriteria/importSetOfCriteria',
      getDataSourcesExport: 'setOfCriteria/getDataSourcesExport',
    }),
    onCreate() {
      this.currentMainData = undefined
    },
    onEdit(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
    },
    onCopySetOfCriteria(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
    },
    onListCriterias(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
      this.$refs['modal-list-criteria'].show()
    },
    onDeleteSetOfCriteria(setOfCriteria) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa <span class="text-danger">${setOfCriteria.name}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteData(setOfCriteria.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getSetOfCriterias(this.filter)
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getSetOfCriteriasFromStore()
    },
    async getSetOfCriteriasFromStore() {
      this.isLoading = true
      try {
        await this.getSetOfCriterias(this.filter)
        const {
          currentPage,
          itemsPerPage,
          ...rest
        } = this.filter
        this.filterExport = rest
        await this.getDataSourcesExport(this.filterExport)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getSetOfCriteriasFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        const selectedColumn = this.columns.findIndex(e => e.field === field)
        this.updateParams({ sort: `${selectedColumn}_${type}` })
      }
      await this.getSetOfCriteriasFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      if (Object.hasOwn(columnFilters, 'setOfCriteriaType')) this.updateParams({ setOfCriteriaType: columnFilters.setOfCriteriaType })
      await this.getSetOfCriteriasFromStore()
    },
    getStatusName(id) {
      return id >= 0 ? STATUSES.find(status => status.value === id).label : ''
    },
    getSetOfCriteriaType(id) {
      return this.setOfCriteriaTypes.find(type => type.id === id)?.name
    },
    getSetOfEvaluateLevel(id) {
      return this.setOfEvaluateLevels.find(level => level.id === id)?.name
    },
    async onSucceed() {
      await this.getSetOfCriteriasFromStore()
    },
    async importExcel(e) {
      const { files } = e.target
      if (!files.length) {
        return
      } if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        // show err
      }
      if (files[0].name) {
        this.tenFile = files[0].name
      }
      const checkFileData = this.readFile(files)
      if (checkFileData && !checkFileData.status) {
        this.showToast('Có lỗi xảy ra', 'XCircleIcon', 'danger')
      }
    },
    readFile(files) {
      const res = {
        status: true,
        index: 0,
      }
      const fileReader = new FileReader()
      fileReader.onload = async ev => {
        try {
          const data = ev.target.result
          const workbook = XLSX.read(data, {
            type: 'binary',
          })
          const wsname = workbook.SheetNames[0]
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            header: 0,
            defval: '',
          })
          const excellist = []
          // Edit data
          if (ws.length > 0) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < ws.length; i++) {
              excellist.push(ws[i])
            }
          } else {
            this.showToast('File không có dữ liệu', 'XCircleIcon', 'danger', 3000)
            this.resetInputFile()
            return
          }
          if (
            !Object.hasOwn(excellist[0], 'MA_LOAI_PHIEU_KHAO_SAT_DANH_GIA')
            || !Object.hasOwn(excellist[0], 'TEN_PHIEU_KHAO_SAT_DANH_GIA')
            || !Object.hasOwn(excellist[0], 'MA_PHIEU_KHAO_SAT_DANH_GIA')
            || !Object.hasOwn(excellist[0], 'MA_THANG_DIEM_DANH_GIA_TONG_QUAN')
            || !Object.hasOwn(excellist[0], 'TRANG_THAI')
          ) {
            this.showToast('File chưa đúng định dạng, vui lòng tải file mẫu', 'XCircleIcon', 'danger', 3000)
            this.resetInputFile()
            return
          }
          this.dataExcel = []
          // eslint-disable-next-line no-plusplus
          for (let k = 0; k < excellist.length; k++) {
            const item = excellist[k]
            // eslint-disable-next-line no-bitwise
            if (item.MA_LOAI_PHIEU_KHAO_SAT_DANH_GIA.trim() === '' || item.TEN_PHIEU_KHAO_SAT_DANH_GIA.trim() === '' || item.MA_PHIEU_KHAO_SAT_DANH_GIA.trim() === '' 
            || String(item.TRANG_THAI).trim() === '') {
              this.showToast(`Dữ liệu đầu vào không được trống các trường thông tin [MA_LOAI_PHIEU_KHAO_SAT_DANH_GIA, TEN_PHIEU_KHAO_SAT_DANH_GIA, MA_PHIEU_KHAO_SAT_DANH_GIA, 
              TRANG_THAI], Kiểm tra thông tin dòng ${k + 1}`, 'XCircleIcon', 'danger', 3000)
              this.resetInputFile()
              return
            }
            const obj = {
              rowNum: k + 1,
              organizationId: getUser().orgId,
              setOfCriteriaType: item.MA_LOAI_PHIEU_KHAO_SAT_DANH_GIA,
              name: item.TEN_PHIEU_KHAO_SAT_DANH_GIA,
              code: item.MA_PHIEU_KHAO_SAT_DANH_GIA,
              setOfEvaluateLevel: item.MA_THANG_DIEM_DANH_GIA_TONG_QUAN,
              status: item.TRANG_THAI,
            }
            this.dataExcel.push(obj)
          }
          this.showTableImport = true
        } catch (e) {
          alert(`Read failure!${e}`)
        }
      }
      fileReader.readAsBinaryString(files[0])
      return res
    },
    resetInputFile() {
      this.$refs.inputFileRef.reset()
    },
    showToast(title, icon, variant, autoHideDelay = 1000) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          autoHideDelay,
        },
      })
    },
    async handleImportSetOfCriteria() {
      this.isLoading = true
      try {
        const res = await this.importSetOfCriteria(this.dataExcel)
        if (res.code === 1) {
          this.showToast('Import thành công', 'CheckIcon', 'success')
          this.resetInputFile()
          await this.getSetOfCriteriasFromStore()
          this.showTableImport = false
        } else {
          this.showToast(res.message, 'XCircleIcon', 'danger', 3000)
          this.resetInputFile()
          this.showTableImport = false
        }
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger', 3000)
        this.resetInputFile()
        this.showTableImport = false
      } finally {
        this.isLoading = false
      }
    },
    downloadFile() {
      const columns = [
        { index: 1, width: 100 },
        { index: 2, width: 300 },
        { index: 3, width: 100 },
        { index: 4, width: 100 },
        { index: 5, width: 50 },
      ]
      const rows = []
      const headerRow = {
        index: 1,
        cells: [
          {
            index: 1,
            value: 'MA_LOAI_PHIEU_KHAO_SAT_DANH_GIA',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 2,
            value: 'TEN_PHIEU_KHAO_SAT_DANH_GIA',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 3,
            value: 'MA_PHIEU_KHAO_SAT_DANH_GIA',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 4,
            value: 'MA_THANG_DIEM_DANH_GIA_TONG_QUAN',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 5,
            value: 'TRANG_THAI',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
        ],
      }
      const headerRow1 = {
        index: 2,
        cells: [
          {
            index: 1,
            value: 'DGLTC',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 2,
            value: 'Khảo sát chất lượng lớp tín chỉ năm 2024',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 3,
            value: 'DGLTC2024',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 4,
            value: 'TDDGTQ1',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 5,
            value: 1,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
        ],
      }
      const headerRow2 = {
        index: 3,
        cells: [
          {
            index: 1,
            value: 'KSCLDV',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 2,
            value: 'Khảo sát chất lượng dịch vụ năm 2025',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 3,
            value: 'KSCLDV2025',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 4,
            value: '',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 5,
            value: 1,
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
        ],
      }
      const headerRow4 = {
        index: 5,
        cells: [
          {
            index: 1,
            colSpan: 1,
          },
          {
            index: 2,
            colSpan: 1,
          },
          {
            index: 3,
            colSpan: 1,
          },
          {
            index: 4,
            colSpan: 1,
          },
          {
            index: 5,
            value: '1: Hoạt động, 0: Không hoạt động',
            colSpan: 1,
          },
        ],
      }

      rows.push(headerRow)
      rows.push(headerRow1)
      rows.push(headerRow2)
      rows.push(headerRow4)
      const worksheets = [{ columns, rows }]
      const workbook = new Workbook({ worksheets }, 'xlsx')
      workbook.save('phieuKhaoSat.xlsx')
    },
    exportExcel() {
      if (this.dataResourecesExport.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không có dữ liệu để xuất!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }

      let excelData = []
      let data = []
      this.dataResourecesExport.forEach((item) => {
        data.push({ 'setOfCriteriaType': item.setOfCriteriaType, 'name': item.name, 'code': item.code, 'setOfEvaluateLevel': item.setOfEvaluateLevel, 'status': this.getStatusName(item.status), 'criteriaType': item.criteriaType,
        'evaluationCriteriaName': item.evaluationCriteriaName, 'maxPoint': item.maxPoint, 'evaluationCriteriaOrderNo': item.evaluationCriteriaOrderNo, 'required': item.required,
        'multipleSelect': item.multipleSelect, 'evaluationCriteriaOptionName': item.evaluationCriteriaOptionName, 'point': item.point, 'evaluationCriteriaOptionOrderNo': item.evaluationCriteriaOptionOrderNo,})
      })

      const header = {}
      this.columnsExport.forEach(column => {
        header[column.field] = column.label
      })

      const headerKeys = Object.keys(header)
      const dataSourceKeys = Object.keys(data[0])
      const duplicates = dataSourceKeys.filter(val => headerKeys.includes(val))
      duplicates.sort((a, b) => headerKeys.indexOf(a) - headerKeys.indexOf(b))
      data.forEach(dataSource => {
        const elm = {}
        duplicates.forEach(key => {
          elm[header[key]] = dataSource[key]
        })
        excelData.push(elm)
      })

      const ws = XLSX.utils.json_to_sheet(excelData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'PhieuKhaoSatDanhGia')
      XLSX.writeFile(wb, 'DanhSachPhieuKhaoSatDanhGia.xlsx')
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
