<template>
  <validation-observer
    ref="setOfCriteriaSaveModalRef"
    #default="{invalid}"
  >
    <b-modal
      id="setOfCriteriaSaveModal"
      body-class="position-static"
      size="lg"
      centered
      :title="isCreated ? 'Thêm phiếu khảo sát/đánh giá' : 'Cập nhật phiếu khảo sát/đánh giá'"
      no-close-on-backdrop
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="setOfEvaluateLevelId">
              <template v-slot:label>
                Loại phiếu khảo sát/đánh giá<span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Loại phiếu khảo sát/đánh giá"
                  rules="required"
                >
                  <v-select
                    placeholder="Chọn loại phiếu khảo sát/đánh giá"
                    v-model="targetSetOfCriteria.setOfCriteriaTypeId"
                    label="name"
                    :options="setOfCriteriaTypes"
                    :reduce="option => option.id"
                  />
                  <b-form-invalid-feedback :state="getElementState(errors)">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group label-for="name">
              <template v-slot:label>
                Tên phiếu khảo sát/đánh giá<span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Tên phiếu khảo sát/đánh giá"
                  rules="required"
                >
                  <b-form-textarea
                    id="name"
                    placeholder="Nhập tên phiếu khảo sát/đánh giá"
                    rows="3"
                    v-model="targetSetOfCriteria.name"
                    :state="getElementState(errors)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="code">
              <template v-slot:label>
                Mã phiếu khảo sát/đánh giá<span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mã phiếu khảo sát/đánh giá"
                rules="required"
              >
                <b-form-input
                  id="code"
                  v-model="targetSetOfCriteria.code"
                  name="code"
                  placeholder="Nhập mã phiếu"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group label-for="setOfEvaluateLevelId">
              <template v-slot:label>
                Thang điểm đánh giá tổng quan
              </template>
              <v-select
                placeholder="Chọn thang điểm đánh giá tổng quan"
                v-model="targetSetOfCriteria.setOfEvaluateLevelId"
                label="name"
                :options="setOfEvaluateLevels"
                :reduce="option => option.id"
                @input="setOfEvaluateLevelOnChange"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trạng thái"
                rules="required"
              >
                <v-select
                  v-model="targetSetOfCriteria.status"
                  :options="statusOptions"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid || (!isCreated && setOfCriteria.allowEditEvaluationCriteria === 0 && setOfCriteria.countSetOfCriteriaObjectUsing > 0)"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('setOfCriteriaSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isSetOfCriteriaSaveLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BModal,
  BOverlay,
  BRow,
  BFormDatepicker,
  BFormTextarea,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import * as events from 'events'
import { getUser } from '@/auth/utils'

export default {
  name: 'SetOfCriteriaSave',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    vSelect,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  props: {
    setOfCriteria: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isSetOfCriteriaSaveLoading: false,
      targetSetOfCriteria: {
        organizationId: 0,
        setOfCriteriaTypeId: null,
        setOfEvaluateLevelId: null,
        name: '',
        code: '',
        status: 1,
        orderNo: 1,
      },
      required,
      isCheckCriterias: true,
      isCheckCriteriaTypeSliders: true,
    }
  },
  computed: {
    events() {
      return events
    },
    ...mapGetters({
      setOfCriteriaTypes: 'setOfCriteria/setOfCriteriaTypes',
      setOfEvaluateLevels: 'setOfCriteria/setOfEvaluateLevels',
      statuses: 'setOfCriteria/statuses',
      maxOrderNo: 'setOfCriteria/maxOrderNo',
      resultCheckCriterias: 'setOfCriteria/resultCheckCriterias',
      resultCheckCriteriaTypeSliders: 'setOfCriteria/resultCheckCriteriaTypeSliders',
    }),
    statusOptions() {
      return this.statuses.map(item => ({ value: item.value, label: item.label }))
    },
    isCreated() {
      return !this.setOfCriteria
    },
  },
  methods: {
    ...mapActions({
      createSetOfCriteria: 'setOfCriteria/createSetOfCriteria',
      updateSetOfCriteria: 'setOfCriteria/updateSetOfCriteria',
      getMaxOrderNo: 'setOfCriteria/getMaxOrderNo',
      checkCriterias: 'setOfCriteria/checkCriterias',
      checkTypeSliders: 'setOfCriteria/checkTypeSliders',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      this.isSetOfCriteriaSaveLoading = true
      if (this.setOfCriteria) {
        this.targetSetOfCriteria = { ...this.setOfCriteria }
      }
      const cUser = getUser()
      const userOrgId = cUser.orgId ? cUser.orgId : 0
      this.targetSetOfCriteria.organizationId = userOrgId

      this.isSetOfCriteriaSaveLoading = false
    },
    onHide() {
      this.$refs.setOfCriteriaSaveModalRef.reset()
      this.targetSetOfCriteria = {
        organizationId: 0,
        setOfEvaluateLevelId: null,
        name: '',
        code: '',
        status: 1,
        orderNo: 1,
      }
    },
    async setOfEvaluateLevelOnChange() {
      this.isCheckCriterias = true
      this.isCheckCriteriaTypeSliders = true
      if(!this.isCreated) {
        await this.checkCriterias(this.setOfCriteria.id)
        if(this.targetSetOfCriteria.setOfEvaluateLevelId != null && !this.resultCheckCriterias) {
          this.isCheckCriterias = false
          this.showToast('Trước tiên, bạn phải thiết lập điểm đầy đủ cho các câu hỏi và các tuỳ chọn câu hỏi trong phiếu khảo sát/đánh giá!', 'XCircleIcon', 'danger', 3000)
        }
        await this.checkTypeSliders(this.setOfCriteria.id)
        if(this.targetSetOfCriteria.setOfEvaluateLevelId === null && !this.resultCheckCriteriaTypeSliders) {
          this.isCheckCriteriaTypeSliders = false
          this.showToast('Trước tiên, bạn phải xoá các câu hỏi loại thanh kéo điểm trong phiếu khảo sát!', 'XCircleIcon', 'danger', 3000)
        }
      }
    },
    async onSave(type = null) {
      if(this.isCheckCriterias === false) {
        this.showToast('Trước tiên, bạn phải thiết lập điểm đầy đủ cho các câu hỏi và các tuỳ chọn câu hỏi trong phiếu khảo sát/đánh giá!', 'XCircleIcon', 'danger', 3000)
        return
      }
      if(this.isCheckCriteriaTypeSliders === false) {
        this.showToast('Trước tiên, bạn phải xoá các câu hỏi loại thanh kéo điểm trong phiếu khảo sát/đánh giá!', 'XCircleIcon', 'danger', 3000)
        return
      }
      const valid = this.$refs
        .setOfCriteriaSaveModalRef
        .validate()
      if (valid) {
        this.isSetOfCriteriaSaveLoading = true
        try {
          const response = this.isCreated
            ? await this.createSetOfCriteria(this.targetSetOfCriteria)
            : await this.updateSetOfCriteria(this.targetSetOfCriteria)
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('setOfCriteriaSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isSetOfCriteriaSaveLoading = false
        }
      }
    },
    showToast(title, icon, variant, autoHideDelay = 1000) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          autoHideDelay,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
